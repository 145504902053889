import classNames from 'classnames'
import HtmlParser from 'html-react-parser'
import React from 'react'
import Text from 'components/Text'
import styles from './AboutUsCard.module.scss'
import { ReactComponent as BlueCloseSvg } from './images/quotes/blue---close.svg'
import { ReactComponent as BlueOpenSvg } from './images/quotes/blue---open.svg'
import { ReactComponent as PurpleCloseSvg } from './images/quotes/purple---close.svg'
import { ReactComponent as PurpleOpenSvg } from './images/quotes/purple---open.svg'
import { ReactComponent as TealCloseSvg } from './images/quotes/teal---close.svg'
import { ReactComponent as TealOpenSvg } from './images/quotes/teal---open.svg'

type Props = {
  children: string
  index: number
}

export default function AboutUsCard({ children, index }: Props) {
  const isTwo = index % 2 === 0
  const isThree = index % 3 === 0

  let color = 'purple'
  let quotes = [<PurpleOpenSvg />, <PurpleCloseSvg />]

  if (isTwo) {
    color = 'cyan'
    quotes = [<TealOpenSvg />, <TealCloseSvg />]
  }

  if (isThree) {
    color = 'blue'
    quotes = [<BlueOpenSvg />, <BlueCloseSvg />]
  }

  return (
    <div className={classNames('AboutUsCard', styles.this, styles[`this---${color}`])}>
      <div className={styles.quote}>{quotes[0]}</div>
      <Text align="center" element="blockquote" weight="medium" className={styles.copy}>
        {HtmlParser(children)}
      </Text>
      <div className={styles.quote}>{quotes[1]}</div>
    </div>
  )
}
