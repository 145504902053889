import React from 'react'
import styles from './Carousel.module.scss'

type Props = {
  children: React.ReactNode[]
  visibleChildrenDesktop?: number
}

type State = {
  position: number
}

export default class Carousel extends React.Component<Props, State> {
  static defaultProps = {
    visibleChildrenDesktop: 3,
  }

  constructor(props: Props) {
    super(props)
    this.state = { position: 0 }
  }

  handleNextEvent = () => {
    if (this.state.position + this.props.visibleChildrenDesktop < this.props.children.length) {
      this.setState((prevState) => ({ position: prevState.position + 1 }))
    }
  }

  handleBackEvent = () => {
    if (this.state.position !== 0) {
      this.setState((prevState) => ({ position: prevState.position - 1 }))
    }
  }

  render() {
    return (
      <div className={styles.main}>
        <div className={styles.base}>
          <div
            className={styles.slider}
            style={{
              transform: `translateX(${
                this.state.position * -(100 / this.props.visibleChildrenDesktop)
              }%)`,
            }}>
            {this.props.children &&
              this.props.children.map((child, index) => (
                <div key={index} className={styles.item}>
                  {child}
                </div>
              ))}
          </div>
        </div>
        {this.props.children && this.props.children.length > 3 && (
          <>
            {this.state.position > 0 && (
              <div className={styles.back} onClick={this.handleBackEvent} />
            )}
            {this.state.position + this.props.visibleChildrenDesktop !==
              this.props.children.length && (
              <div className={styles.forward} onClick={this.handleNextEvent} />
            )}
          </>
        )}
      </div>
    )
  }
}
